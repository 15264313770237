import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import StarKitchenTriangle from '../components/star-kitchen-triangle'
import StarKitchenTitle from '../components/star-kitchen-title'
import TourSplash from '../components/tour/tour-splash'
import TransparentTextUpdate from '../components/updates/transparent-text-update'
// import ColorRedSept13 from '../components/updates/color-red-sept-13'
import Container from '../components/container'
// import ArdmoreLiveStream from '../components/updates/ardmore-march-13'
// import JusticeComesAlive from '../components/updates/justice-comes-alive'
// import CovidSale from '../components/updates/covid-sale'
import AprilTourSale from '../components/updates/april-tour-sale'
// import KnewConscious from '../components/updates/knew-conscious'
// import Punches from '../components/updates/punches'
// import Entirely from '../components/updates/entirely'
import DarknessAndDemons from '../components/updates/darkness-and-demons'
import { EventType } from '../types'
import { ActionButton, UpdateActions, UpdateText, UpdateTitle } from '../components/update'
import styled from 'styled-components'
import Button from '../components/button'

export const query = graphql`
  {
    db {
      upcoming_events(
        where: { band: { name: { _eq: "Star Kitchen" } } }
        order_by: [{ date: asc }]
      ) {
        id
        date
        name
        venue {
          name
          city
          state
          country
          website
        }
        set_time
        set_length
        website
        facebook_event
        event_tickets {
          id
          link
          label
        }
        headliner_band {
          name
        }
        is_festival
        start_date
        end_date
        canceled
        canceled_notes
        notes
      }
    }
  }
`
// export const query = graphql`
//   {
//     db {
//       upcoming_events(
//         limit: 10,
//         order_by: {date: asc},
//         where: {
//           band: {
//             name: {_eq: "Star Kitchen"}
//           }
//         }
//       ) {
//         id
//         date
//         name
//         set_time
//         set_length
//       }
//     }
//   }
// `

type LeadingContentType = {
  type: 'transparent-text' | string
  content: string
}

const leadingContent: [LeadingContentType] = [
  // {
  //   type: 'transparent-text',
  //   content: `
  //     We're so excited to begin performing concerts with in-person audiences again!
  //     The shows at Knew Conscious in Denver will have a reduced capacity
  //     and are
  //     <a href="https://fans.live/products/star-kitchen" target="_blank">available to stream on Fans.live</a>.
  //     Show starts at 10pm MT with
  //     Casual Commander and Mikey Thunder. Star Kitchen will perform 11:30pm - 1:30am MT.
  //     For those of you up late night on the east coast, that's 1:30am - 3:30am ET.
  //     Hope to see you soon! -&nbspTeam&nbspStar&nbspKitchen
  //   `,
  // },
]

const IndexTitle = styled.h3`
  margin: 2.5em 0 1em;
  text-align: center;
  text-transform: uppercase;
`

interface IndexPageProps {
  data: {
    db: {
      upcoming_events: [EventType]
    }
  }
}

function IndexPage({ data }: IndexPageProps): ReactElement {
  return (
    <Layout showNav={false} canonical="/">
      <Container style={{ paddingTop: 20 }}>
        <StarKitchenTriangle />
        <StarKitchenTitle />
        {/*
        <Nav>
          <Link to="/tour/">Tour</Link>
          <Link to="/updates/">Updates</Link>
          <Link to="/about/">About</Link>
        </Nav>
        */}
      </Container>
      {leadingContent.map((def, index) => {
        switch (def.type) {
          case 'transparent-text':
            return (
              <TransparentTextUpdate key={index}>
                <UpdateText dangerouslySetInnerHTML={{ __html: def.content }} />
              </TransparentTextUpdate>
            )
          default:
            return null
        }
      })}
      <TourSplash data={data} />
      {/* <div>
        <img alt="" style={{ display: "block", marginTop: "24px", marginLeft: "auto", marginRight: "auto" }} src="https://i.seadn.io/gae/B0r8oNt84xJb5g_f0j-kv61JZRsnDtQ-jN4wqgqgb8Q_OtF-tIA5NdHStSg0VPDmF1875GBN3D8mthc9_WmY088nNenGc81MJiDygw?auto=format&w=500" />
        <div style={{ lineHeight: "12px", fontSize: "12px", display: "block", maxWidth: "500px", margin: 0, padding: 0, textAlign: 'right', marginLeft: "auto", marginRight: "auto", marginTop: "8px" }}>
          <a target='_blank' rel="noopener noreferrer" href="https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/8939550754538691991590313441157858199871277822515196370331130811481015189604" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ height: "16px", width: "16px", marginRight: '4px' }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
          </svg><div>Across The Universe by Ricardo Takamura</div></a>
          <div style={{ fontSize: "6px" }}>Owned by <a href="https://opensea.io/djbrownie" target="_blank" rel="noopener noreferrer">djbrownie.eth</a>. Proceeds of photo sales are being used to help save a family of cats with special needs.</div>
        </div>
      </div> */}
      {/* <IndexTitle>Past News and Announcements</IndexTitle> */}
      <DarknessAndDemons />

      <UpdateTitle>Streaming Now</UpdateTitle>
      <UpdateActions style={{ display: 'flex', alignItems: 'center', whiteSpace: 'pre', flexWrap: 'wrap', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
        <Button block style={{ marginTop: '8px', marginRight: '8px' }}>
          <a
            href="https://open.spotify.com/artist/7pHa2zdgnRHA8Dnk9cPB5f"
            rel="noreferrer noopener"
            target="_blank"
            style={{ display: "block", textAlign: "center" }}
          >
            Spotify
          </a>
        </Button>
        <Button block style={{ marginTop: '8px', marginRight: '8px' }}>
          <a
            href="https://music.apple.com/artist/star-kitchen/1518920442"
            rel="noreferrer noopener"
            target="_blank"
            style={{ display: "block", textAlign: "center" }}
          >
            Apple Music
          </a>
        </Button>
        <Button block style={{ marginTop: '8px', marginRight: '8px' }}>
          <a
            href="https://2nu.gs/StarKitchen"
            rel="noreferrer noopener"
            target="_blank"
            style={{ display: "block", textAlign: "center" }}
          >
            Full Shows on Nugs.net
          </a>
        </Button>
      </UpdateActions>
      {/* <AprilTourSale /> */}
      {/* <KnewConscious /> */}
      {/* <Entirely /> */}
      {/* <ArdmoreLiveStream /> */}
      {/* <JusticeComesAlive /> */}
      {/* <LiveFromOutThere /> */}
      {/* <CovidSale /> */}
      {/* <ColorRedSept13 /> */}
    </Layout>
  )
}

export default IndexPage
