import React, { ReactElement } from 'react'
import styled from 'styled-components'

import TourList from './tour-list'
import { EventType } from '../../types'

const TourTitle = styled.h3`
  margin: 0.5em 0 1em;
  text-align: center;
  text-transform: uppercase;
`

interface TourSplashProps {
  data?: {
    db?: {
      upcoming_events?: [EventType]
    }
  }
  links?: 'internal' | 'external'
}

function TourSplash({ data, links }: TourSplashProps): ReactElement | null {
  const events = data?.db?.upcoming_events ?? []
  return events.length ? (
    <div>
      <TourTitle>Upcoming Shows</TourTitle>
      <TourList events={events} links={links} />
    </div>
  ) : null
}

export default TourSplash
