import React from 'react'
import styled from 'styled-components'
import TourDate from './tour-date'
import { EventType, LinkType } from '../../types'

const Container = styled.div`
  font-size: 14px;
  /*
  @media (min-width: 390px) {
    font-size: 9px;
  }
  @media (min-width: 490px) {
    font-size: 10px;
  }
  @media (min-width: 600px) {
    font-size: 12px;
  }
  */
  @media (min-width: 768px) {
    font-size: 14px;
  }
`

function sortByDate(events: [EventType]) {
  return events.sort((a, b) => {
    if (!a.date) {
      return 1
    }
    if (!b.date) {
      return -1
    }
    if (a.date > b.date) {
      return 1
    }
    if (a.date < b.date) {
      return -1
    }
    return 1
  })
}

interface TourListProps {
  events: [EventType],
  links?: "external" | "internal"
}

function TourList({ events, links = "external" }: TourListProps) {
  return (
    <Container>
      {events.map(event => {
        // const slug = `${event.date}-${event.id}`
        return (
          <TourDate key={event.id} event={event} links={links} />
        )
      })}
    </Container>
  )
}
export default TourList
