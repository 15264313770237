// import React from 'react'
import styled from 'styled-components'

interface ButtonProps {
  big?: boolean
  block?: boolean
  first?: boolean
}

const Button = styled.span<ButtonProps>`
  background-color: #f4ac08;
  border-radius: 3px;
  color: #000;
  display: ${(props) => (props.block ? 'block' : 'inline-block')};
  font-size: ${(props) => (props.big ? '1rem' : '.85rem')};
  padding: ${(props) => (props.big ? '0.5rem 1rem' : '0.25rem 0.5rem')};
  transition: color 0.3s ease-in;

  :hover {
    color: #fff;
    transition: color 0.3s ease-out;
  }

  a {
    color: #000;
    ${(props) => (props.block ? 'display: block;' : '')}
    text-decoration: none;
  }

  @media (min-width: 480px) {
    font-size: 1rem;
  }
  @media (min-width: 600px) {
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
  }
`

export default Button
