import React, { ReactNode, ReactElement } from 'react'
import {
  TransparentUpdateContainer,
  UpdateWideContent,
  UpdateText,
} from '../update'

interface OnlyChildrenProps {
  children?: ReactNode
}

function TransparentTextUpdate({ children }: OnlyChildrenProps): ReactElement {
  return (
    <TransparentUpdateContainer>
      <UpdateWideContent>
        <UpdateText>{children}</UpdateText>
      </UpdateWideContent>
    </TransparentUpdateContainer>
  )
}

export default TransparentTextUpdate
