import styled from 'styled-components'
import Button from './button'

export const UpdateContainer = styled.div`
  align-items: stretch;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-between;
  margin: 2rem auto;
  max-width: 100%;
  padding: 3%;
  text-align: left;
  width: 800px;
`

export const TransparentUpdateContainer = styled(UpdateContainer)`
  background: transparent;
  padding: 0;
`

export const CenteredUpdateContainer = styled(UpdateContainer)`
  justify-content: space-around;
`

export const UpdateTitle = styled.h3`
  margin: 0 0 16px;
  flex: 1 0 100%;
  text-align: center;
  text-shadow: black 0.2em 0.2em;
  text-transform: uppercase;
  width: 100%;
`

export const UpdateImage = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  @media (min-width: 500px) {
    margin-bottom: 0;
    width: 40%;
  }
`

export const UpdateContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 500px) {
    width: 55%;
  }
`

export const UpdateWideContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  @media (min-width: 500px) {
    text-align: justify;
  }
`

export const UpdateText = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  line-height: 1.3;
  @media (min-width: 600px) {
    font-size: 1rem;
  }
`

export const UpdateActions = styled.div`
  align-self: flex-start;
  margin-top: auto;
`

export const ActionButton = styled(Button)`
  margin-top: ${(props) => (props.first ? '0' : props.big ? '1rem' : '0.25rem')};
`

export const SingleColumnMobileImage = styled(UpdateImage)`
  flex-basis: 100%;
  margin-bottom: 1rem;

  @media (min-width: 600px) {
    flex-basis: 45%;
  }
`

export const FullWidthImage = styled(UpdateImage)`
  flex-basis: 100%;
  margin-bottom: 1rem;
  width: auto;
`
