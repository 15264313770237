import React, { ReactNode } from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import Separator, { PotentialNewlineSeparator } from '../separator'
import { Link } from 'gatsby'
import { EventType } from '../../types'

const Container = styled.div`
  margin: 0 0 1em;
  max-width: 100%;
  text-align: center;

  @media (min-width: 769px) {
    margin-bottom: 0.5em;
  }
`

interface DimmerProps {
  on: boolean
  children?: ReactNode
}

const DimmerOn = styled.span`
  opacity: 0.25;
`
const DimmerOff = styled.span``
function Dimmer({ on, children }: DimmerProps) {
  return on ? (
    <DimmerOn>{children}</DimmerOn>
  ) : (
    <DimmerOff>{children}</DimmerOff>
  )
}

const EventExternalLink = styled.a`
  color: ${colors.offWhite};
  display: inline-block;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  :hover {
    color: #fff;
  }
`
const EventInternalLink = styled(Link)`
  color: ${colors.offWhite};
  display: inline-block;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  :hover {
    color: #fff;
  }
`

const OrangeText = styled.span`
  color: ${colors.orange};
`
const VenueName = styled.span`
  display: inline-block;
`
const ListingNotes = styled.span`
  display: inline-block;
  font-size: .8em;
  padding-left: 8px;
`


const TourDateCanceled = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ::before {
    content: '';
    border-top: 1px solid ${colors.orange};
    display: block;
    transform: translateY(50%);
    opacity: 0.5;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
`

const HeadliningAct = styled.span`
  font-size: 0.75em;

  @media (min-width: 460px) {
    display: inline;
    font-size: 1em;
  }
`

const With = styled.span`
  text-transform: none;
`

type MonthMapType = {
  [key: string]: string
}
const monthMap: MonthMapType = {
  '01': 'Jan.',
  '02': 'Feb.',
  '03': 'Mar.',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'Aug.',
  '09': 'Sept.',
  '10': 'Oct.',
  '11': 'Nov.',
  '12': 'Dec.',
}

function formatDate(date?: string): string {
  if (!date) {
    return 'TBD'
  }
  const parts = date.split('-')
  if (parts.length !== 3) {
    return date
  }
  const day = parseInt(parts[2], 10)
  const month = monthMap[parts[1]]
  return month + ' ' + day
}

interface TourDateProps {
  event: EventType,
  links?: "external" | "internal",
}

function TourDate({ event, links }: TourDateProps) {
  let name = event.name
  if (!name && event.venue) {
    name = event.venue.name
  }
  let location = ''
  if (event.venue) {
    location = event.venue.city ?? ''
    if (event.venue.state) {
      location += ', ' + event.venue.state
    }
    if (event.venue.country !== 'US' && event.venue.country !== '') {
      location += ', ' + event.venue.country
    }
  }
  let website = event.website
  // TODO add in looking at the event.event_tickets
  if (!website) {
    website = event.facebook_event
  }
  if (!website && event.venue) {
    website = event.venue.website
  }
  const slug = `${event.date}-${event.id}`
  const LinkComponent =
    links === 'external' ? EventExternalLink : EventInternalLink
  const externalLinkProps = { href: website, target: '_blank' }
  const internalLinkProps = { to: '/tour/' + slug }
  const linkProps = links === 'external' ? externalLinkProps : internalLinkProps

  console.log({ event })
  const eventDate = (event.start_date && event.end_date) ? `${formatDate(event.start_date)} - ${formatDate(event.end_date)}` : formatDate(event.date)

  return (
    <Container>
      <LinkComponent {...linkProps}>
        <Dimmer on={event.canceled}>
          <OrangeText>
            {eventDate}
            <Separator>/</Separator>
            {location}
          </OrangeText>
          <PotentialNewlineSeparator />
          <VenueName>{name}</VenueName>
          {event.notes ? (
            <ListingNotes>{event.notes}</ListingNotes>
          ) : null}
          {event.headliner_band ? (
            <HeadliningAct>
              <PotentialNewlineSeparator>/</PotentialNewlineSeparator>
              <With>with</With> {event.headliner_band.name}
            </HeadliningAct>
          ) : null}
        </Dimmer>
        {event.canceled ? (
          <TourDateCanceled>{event.canceled_notes}</TourDateCanceled>
        ) : null}
      </LinkComponent>
    </Container>
  )
}

export default TourDate
