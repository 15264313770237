import React, { ReactElement } from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import {
  UpdateContainer,
  UpdateContent,
  UpdateTitle,
  // UpdateLeftCol,
  UpdateText,
  UpdateImage,
  UpdateActions,
  ActionButton,
} from '../update'

type UpdateActionType = {
  label: string
  link: string
  newPage: boolean
}

export const actions: UpdateActionType[] = [
  {
    label: 'Stream or Purchase on Apple Music',
    link: 'https://music.apple.com/us/album/darkness-demons-single/1696865427',
    newPage: true,
  },
  {
    label: 'Stream on Spotify',
    link: 'https://open.spotify.com/album/73h8XPQDjXWWlQsywzNuxD',
    newPage: true,
  },
  {
    link: 'https://www.amazon.com/music/player/albums/B0CBLK12XF',
    label: 'Purchase the MP3 on Amazon Music',
    newPage: true,
  },
  // {
  //   label: 'Stream or Purchase on Bandcamp',
  //   link: 'https://starkitchen.bandcamp.com/track/punches-feat-nate-werth',
  //   newPage: true,
  // },
  // {
  //   label: 'Stream on YouTube Music',
  //   link: 'https://music.youtube.com/browse/MPREb_l44OiVlcTAp',
  //   newPage: true,
  // },
  // {
  //   label: 'Stream on SoundCloud',
  //   link:
  //     'https://soundcloud.com/colorredmusic/star-kitchen-punches/s-0UFbu5dZX4S',
  //   newPage: true,
  // },
  // {
  //   label: 'Stream on Tidal',
  //   link: 'https://listen.tidal.com/album/167554411',
  //   newPage: true,
  // },
  // {
  //   label: 'Stream on Deezer',
  //   link: 'https://www.deezer.com/us/album/155283232',
  //   newPage: true,
  // },
]

export default function Punches(): ReactElement {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/darkness-and-demons-album-cover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <UpdateContainer>
      <UpdateTitle>Latest Release: Darkness &amp; Demons</UpdateTitle>
      <UpdateImage>
        <a href="https://color-red.com/products/darkness-demons">
          <Img
            fluid={data.file.childImageSharp.fluid}
            fadeIn={false}
            alt=""
            critical={false}
            loading="eager"
            style={{ maxWidth: '400px', margin: '0 auto' }}
          />
        </a>
      </UpdateImage>
      <UpdateContent>
        <UpdateText>
          Two original instrumental compositions released August 4, 2023 on Color Red records.
          Recorded by Kiley Cubicciotti at <a href="https://www.instagram.com/secondstreetmusic/" target="_blank" rel="noopener">Second Street Music</a>. Mixed and Mastered by <a href="https://www.instagram.com/jlaskin1/" rel="noopener" target="_blank">Joshua Fairman</a>.
        </UpdateText>
        <UpdateActions>
          {actions.map(({ label, link, newPage }, index) => (
            <ActionButton key={index} block>
              <a
                href={link}
                rel={`${newPage ? 'noreferrer' : ''}`}
                target={`${newPage ? '_blank' : ''}`}
              >
                {label}
              </a>
            </ActionButton>
          ))}
        </UpdateActions>
      </UpdateContent>
    </UpdateContainer>
  )
}
